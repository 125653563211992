export default {
  MsgError: {
    name: "Please,contact with the system administrator ",
    slug: "Error",
    i18n: "Error"
  },
  DontHaveCycles: {
    name: "You haven't made cycles",
    slug: "DontHaveCycles",
    i18n: "DontHaveCycles"
  },
  InformationEarnings: {
    name:
      "If you need to view more information about your earnings by period, please, select the table row",
    slug: "InformationEarnings",
    i18n: "InformationEarnings"
  },
  ColorsTableGenealogy: {
    name: 'The "Level" column has its values with colors, the meaning is:',
    slug: "ColorsTableGenealogy",
    i18n: "ColorsTableGenealogy"
  },
  MsgNotAllow10Rows: {
    name: "It only allows maximum 9 levels",
    slug: "MsgNotAllow10Rows",
    i18n: "MsgNotAllow10Rows"
  },
  MsgDistNotExist: {
    name: "The Distributor ID doesn't exist in your binary tree",
    slug: "MsgDistNotExist",
    i18n: "MsgDistNotExist"
  },
  MsgWelcome: {
    name: "Welcome back, please login to your account",
    slug: "MsgWelcome",
    i18n: "MsgWelcome"
  },
  MsgRecoverPassword: {
    name:
      "Please enter your email address and we'll send you a verification code to reset or update your password.",
    slug: "MsgRecoverPassword",
    i18n: "MsgRecoverPassword"
  },
  MsgNewPassword: {
    name: "Please enter your new password",
    slug: "MsgNewPassword",
    i18n: "MsgNewPassword"
  },
  MsgInvalidPassword: {
    name:
      "The password must have minimum 9 characters with uppercase, lowercase, numbers and special characters, else the button will not active",
    slug: "MsgInvalidPassword",
    i18n: "MsgInvalidPassword"
  },
  MsgNotBinaryTree: {
    name:
      "It has occurred a fail to recover the binary tree. Information isn't correct. Please, check with the administrator",
    slug: "MsgNotBinaryTree",
    i18n: "MsgNotBinaryTree"
  },
  MsgFromLevelLess: {
    name: 'The "{0}" field must be less than "{1}"',
    slug: "MsgFromLevelLess",
    i18n: "MsgFromLevelLess"
  },
  MsgStatusByPeriod: {
    name:
      '"You can search your qualification or another distributor of your downline in different periods"',
    slug: "MsgStatusByPeriod",
    i18n: "MsgStatusByPeriod"
  },
  MsgConsiderInformation: {
    name: "Please you consider for the next information:",
    slug: "MsgConsiderInformation",
    i18n: "MsgConsiderInformation"
  },
  MsgPayTransaction: {
    name: "USD $1 will be charged for each transfer requested.",
    slug: "MsgPayTransaction",
    i18n: "MsgPayTransaction"
  },
  MsgDaysToPayment: {
    name:
      "Transfers will be available on your ewallet the next Wednesday after transfer was requested at 16:00 ET or later.",
    slug: "MsgDaysToPayment",
    i18n: "MsgDaysToPayment"
  },
  MsgNotEnoughMoney: {
    name: "You must have least USD $2 to do transferr",
    slug: "MsgNotEnoughMoney",
    i18n: "MsgNotEnoughMoney"
  },
  MsgNotPayment: {
    name: "We are not processing transfers request at this time",
    slug: "MsgNotPayment",
    i18n: "MsgNotPayment"
  },
  MsgWalletNotExist: {
    name:
      "The user {0} doesn't exista in E-wallet. Please, you need to create an account to receive payments.",
    slug: "MsgWalletNotExist",
    i18n: "MsgWalletNotExist"
  },
  MsgYouHaveEarned: {
    name: "You have earned",
    slug: "MsgYouHaveEarned",
    i18n: "MsgYouHaveEarned"
  },
  MsgTipAndFee: {
    name: "at tips and customized fee",
    slug: "MsgTipAndFee",
    i18n: "MsgTipAndFee"
  },
  MsgPaymentMade: {
    name: "Payment have been made correctly",
    slug: "MsgTipAndFee",
    i18n: "MsgTipAndFee"
  },
  MsgPaymentNotMade: {
    name: "Payment haven't been made correctly",
    slug: "MsgTipAndFee",
    i18n: "MsgTipAndFee"
  },
  MsgStageBill: {
    name: "Only bookings are on billing will be paid",
    slug: "MsgStageBill",
    i18n: "MsgStageBill"
  },
  MsgTax: {
    name:
      'Taxes will be charged for each booking. View "Tax" column in the detail',
    slug: "MsgTax",
    i18n: "MsgTax"
  },
  MsgTotalPendingCharges: {
    name:
      "Will be charged to your transfer. You could there is received double payments or we didn't do the discount USD $1 por other transfers made",
    slug: "MsgTotalPendingCharges",
    i18n: "MsgTotalPendingCharges"
  },
  MsgWriteToCustomerService: {
    name:
      "If you have something question, you must write to servicioalcliente@visiontravel.net with your issue",
    slug: "MsgWriteToCustomerService",
    i18n: "MsgWriteToCustomerService"
  },
  MsgOneTransferAtDay: {
    name: "You only can make one transfer at day",
    slug: "MsgOneTransferAtDay",
    i18n: "MsgOneTransferAtDay"
  },
  MsgCustomizeTheme: {
    name: "Customize and preview in real time",
    slug: "MsgCustomizeTheme",
    i18n: "MsgCustomizeTheme"
  },
  MsgNotAllowGenealogy: {
    name:
      "You can't see the information about Genealogy. If you want getting the data, you must update your plan or do your monthly payment",
    slug: "MsgNotAllowGenealogy",
    i18n: "MsgNotAllowGenealogy"
  },
  MsgAccountDiscount: {
    name: "We'll deduct the purchase payment in the follow account",
    slug: "MsgAccountDiscount",
    i18n: "MsgAccountDiscount"
  },
  MsgRequiredField: {
    name: "You must fill in the required fields",
    slug: "MsgRequiredField",
    i18n: "MsgRequiredField"
  },
  MsgAllRequiredField: {
    name: "All fields are required",
    slug: "MsgAllRequiredField",
    i18n: "MsgAllRequiredField"
  },
  MsgAcquireMembership: {
    name: "You want to acquire a {0} membership for {1} {2}",
    slug: "MsgAcquireMembership",
    i18n: "MsgAcquireMembership"
  },
  MsgAcquireMembership1: {
    name: "MsgAcquireMembership1",
    slug: "MsgAcquireMembership1",
    i18n: "MsgAcquireMembership1"
  },
  MsgAcquireMembership2: {
    name: "MsgAcquireMembership1",
    slug: "MsgAcquireMembership2",
    i18n: "MsgAcquireMembership2"
  },
  MsgPendingMembership: {
    name: "You owe {0} membership years",
    slug: "MsgPendingMembership",
    i18n: "MsgPendingMembership"
  },
  MsgInfotraxOffline: {
    name:
      "We apologize for the inconvenience caused, at this time our commissions and genealogy system is not working properly",
    slug: "MsgInfotraxOffline",
    i18n: "MsgInfotraxOffline"
  },
  MsgJoinTelegram: {
    name:
      "You can join to our community Telegram for receiving notifications about your purchases, they can be about tourism or binary. Don't worry, they'll only be related with your activity.",
    slug: "MsgJoinTelegram",
    i18n: "MsgJoinTelegram"
  },
  MsgWhatWait: {
    name: "What are you waiting for? Join up!",
    slug: "MsgWhatWait",
    i18n: "MsgWhatWait"
  },
  MsgGenerate: {
    name:
      "Remember, when you pay online and the payments are confirmed (E-wallet), You only must do click in the 'Generate' button and that's all, your order and accesses have been generated. Make it easy!",
    slug: "MsgGenerate",
    i18n: "MsgGenerate"
  },
  MsgLinkMembership: {
    name:
      "You can send this link to your customers and they'll do their affiliation. You must be attent to complete the binary information, if you want to receive the notification when the customer finish the process, please, Join to our Telegram community!. Click in the JOIN button or in your profile.",
    slug: "MsgLinkMembership",
    i18n: "MsgLinkMembership"
  },
  MsgNotInvest: {
    name:
      "The system has failed for completing the investment process on Block Invest",
    slug: "MsgNotInvest",
    i18n: "MsgNotInvest"
  },
  MsgInvest: {
    name:
      "Congratulations! You've done an investment on Block Invest. GOOD LUCK WITH THE EARNINGS!",
    slug: "MsgInvest",
    i18n: "MsgInvest"
  },
  MsgNotBalance: {
    name:
      "The account number #{0} has balance USD {1}, is less than the amount",
    slug: "MsgNotBalance",
    i18n: "MsgNotBalance"
  },
  MsgNotTransferEwallet: {
    name: "We are not processing transfers request to Ewallet at this time",
    slug: "MsgNotTransferEwallet",
    i18n: "MsgNotTransferEwallet"
  },
  MsgFinancialCharge:{
    name: "To remember! We'll charge you 5% of the total transaction of each personalized fee for 'Financial Charges', this by virtue of the collection of money.",
    slug: "MsgFinancialCharge",
    i18n: "MsgFinancialCharge"
  },
  MsgFinancialChargeFee:{
    name: "The custom fee percentage ranging from 8% to 15% will have a charge equal to the percentage you added",
    slug: "MsgFinancialChargeFee",
    i18n: "MsgFinancialChargeFee"
  },
  MsgFinancialChargeAdded:{
    name: "The added percentage that goes from 1% to 7% of fee, will have a fixed charge of 7%.",
    slug: "MsgFinancialChargeAdded",
    i18n: "MsgFinancialChargeAdded"
  },
  MsgStartDateAfterEndDate: {
    name: "Start date must less than end date",
    slug: "MsgStartDateAfterEndDate",
    i18n: "MsgStartDateAfterEndDate"
  },
  MsgItineraryBeforeNow: {
    name: "Itinerary's dates must greater than now",
    slug: "MsgItineraryBeforeNow",
    i18n: "MsgItineraryBeforeNow"
  },
  MsgItineraryAfterArrival: {
    name: "Departure date must less than arrival date",
    slug: "MsgItineraryAfterArrival",
    i18n: "MsgItineraryAfterArrival"
  }
};
